
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { DetailInfo } from '@/types/serviceFacility'

@Component({
  name: 'ServiceFacilityDetailInfo',
  components: { MapLocation },
  filters: {
    isNetRed (value: string) {
      return value ? value === '1'
        ? '是' : value === '0'
          ? '否' : '' : ''
    }
  }
})
export default class extends Vue {
  private detailInfo: DetailInfo | null = null
  created () {
    const id = this.$route.query.id as string
    this.getDetail(id)
  }

  getParkNum (num: number) {
    let txt = ''
    if (num >= 0) {
      txt = Math.round(num * 0.3) + '/' + num
    }
    return txt
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.serviceFacility.selectServiceFacilitiesByServiceId, {
        serviceId: id
      })
      .then((res) => {
        this.detailInfo = res || null
      })
  }
}
